function MessageShablon({ message }) {
  return (
    <div className='border-b border-gray-300 pb-5  bg-lime-100s  items-center justify-center'>
      <div className='  col gap-1 w-full'>
        {/* <div className='text-sm'>{formatDate(message.created, 'short', 'de-DE')}</div> */}
        {/* <div className='whitespace-break-spaces'>{message.field_content}</div> */}
        <div className='text-lg mb-3'>{message?.field_shablon?.label}</div>
        { message?.field_tokens && Object.values(message.field_tokens).map((token, i) => {
            return  (
              <div key={i} className='flex flex-row gap-3 text-sm'>
                <div className='w-44 font-bold'>{token?.label}</div>
                <div className='w-full italic'>{token?.value || '---'}</div>
              </div>
            )
              
             
        }) }
      </div>
    </div>
  )
}

export default MessageShablon