import { Mark, mergeAttributes } from '@tiptap/core'
import { getMarkRange } from '@tiptap/core'
import { TextSelection } from 'prosemirror-state'

const TokenMark = Mark.create({
  name: 'token',
  excludes: '_',

  addAttributes() {
    return {
      // contenteditable: {
      //   default: false,
      // },
      id: {
        default: null,
      },
      type: {
        default: null,
      },
      label: {
        default: null,
      },
      example: {
        default: null,
      },
      widget: {
        default: null,
      }

    }
  },
 
  parseHTML() {
    return [
      {
        tag: 'token',
        getAttrs: element => {
          console.log(element);
          
          // Hier kannst du vorhandene Attribute aus dem DOM übernehmen
          const id = element.getAttribute('id')
          const type = element.getAttribute('type')
          const label = element.getAttribute('label')
          const example = element.getAttribute('example')
          const widget = element.getAttribute('widget')
          return { id, type, label, example, widget }
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    // HTMLAttributes enthält jetzt auch die definierten Attribute (id, dataCustom)
    return ['token', mergeAttributes(HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setToken: (attributes) => ({ commands }) => {
        return commands.toggleMark('token', attributes)
      },
      updateToken: (attributes) => ({ chain, editor, state, dispatch }) => {
        const { from } = state.selection
        const { doc, tr } = state
        const tokenMark = editor.schema.marks.token

        // 1) Prüfen, ob die aktuelle Cursor-Position in einem Token-Mark liegt
        const markRange = getMarkRange(doc.resolve(from), tokenMark)
        if (!markRange) {
          console.warn('Kein Token-Mark an dieser Stelle vorhanden.')
          return false
        }

        // 2) Selektion auf den gesamten Token setzen
        tr.setSelection(TextSelection.create(doc, markRange.from, markRange.to))
        editor.view.dispatch(tr)
        // editor.view.focus()

        // 3) Jetzt die Token-Attribute aktualisieren
        //    (wir befinden uns im richtigen Bereich)
        return chain().focus().setMark('token', attributes).run()
      },
      unsetToken: () => ({ commands }) => {
        return commands.unsetMark('token')
      },
    }
  },
})

export default TokenMark
