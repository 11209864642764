import { parseHTML } from '../Utils'

const Html = ({ html = null, className }) => {

  return html ? (
    <div className={`parsed ${className}`}>
      { parseHTML(html) }
    </div>
  ) : null
}

export default Html