function MessageClient({ message }) {
  return (
    <div className='flex justify-end'>
      <div className='px-4 py-2 rounded-xl rounded-tr-none	 bg-main-600 text-white prose min-w-96'>
        {/* <div className='text-sm'>{ formatDate(message.created, 'short', 'de-DE') }</div> */}
         <p>{ message.field_content } </p>
      </div>
    </div>
  )
}

export default MessageClient