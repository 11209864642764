import React, { useState, useRef, useEffect } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useLoader, postToApi } from '../Utils'
import { useMutation, useQueryClient } from 'react-query'
import ShablonFormFull from '../components/ShablonFormFull'
import ChatForm from '../components/ChatForm'
import '../assets/form.scss'
// import Markdown from 'react-markdown'
// import MarkdownIt from 'markdown-it'

import MessageClient from '../partials/MessageClient'
import MessageChatGPT from '../partials/MessageChatGPT'
import MessageShablon from '../partials/MessageShablon'

const Chat = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [ searchParams ] = useSearchParams()
  const { uuid } = useParams()
  const [ mode ] = useState('stream')

  const shablon_uuid = searchParams.get('shablon')

  const { data: chat } = useLoader('chat', { uuid: uuid })
  const { data: shablon } = useLoader('shablon', { uuid: shablon_uuid })

  const {mutate: sendChat} = useMutation(async (chat) => {
    const { data: newChat } = await postToApi(`chat`, chat)
    return newChat
  }, {
    onMutate: async () => { 
      await queryClient.cancelQueries([`chats`])
    },
    onSuccess: (respone) => {
      queryClient.setQueryData([`chat`, respone.chat.uuid], (old = {}) => (respone.chat)) 
      queryClient.setQueryData([`chat`, respone.chat.uuid, 'messages'], (messages = []) => {
        messages.push(respone.messages.client)
        messages.push(respone.messages.assistent)
        return messages;
      })

      queryClient.setQueryData([`chats`], (old = []) => [respone.chat, ...old]) 
      navigate(`/chat/${respone.chat.uuid}`, { replace: true })
    },
  })
 
  const submitMessage =  async (message) => {
    sendChat({
      chat: uuid,
      assistent: 'chatgpt',
      mode: mode,
      message: {
        bundle: 'text',
        ...message,
      }
    }, {})
 
    return true
  }
 
  const submitDrompt = (tokens) => {
    sendChat({
      chat: uuid,
      assistent: 'chatgpt',
      mode: mode,
      message: {
        bundle: 'shablon',
        shablon: shablon.uuid,
        tokens: tokens,
      }
    }, {})
  
    return true
  }

  return (
    <>
      { uuid && <MessagesChat uuid={chat?.uuid}  />} 
      <div className='sticky grow-0 col items-center before:absolute before:inset-0 before:bg-white dark:before:bg-gray-900 bottom-0 left-20 sidebar-expanded:left-64 transition-all'>
        {shablon ? 
          <ShablonFormFull shablon={shablon} onSubmit={submitDrompt}  /> : 
          <ChatForm submitMessage={submitMessage}  /> 
        }
      </div>
    </>
  )
}
 
const MessagesChat = ({ uuid, buzzy }) => {
  const { data: messages, isLoading } = useLoader('messages', { uuid: uuid })
  const endRef = useRef(null);

  // // Scroll to the bottom whenever messages change
  useEffect(() => {
    if (endRef.current) {
      // console.log(endRef.current.scrollTop, isLoading);
      // endRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isLoading]);

  // endRef.current.scrollIntoView({ behavior: 'smooth' });
  return (
    <div className='grow container col gap-8 w-full max-w-4xl mx-auto pt-6 bg'>
      { messages && (messages).map((message, i) => {
          switch (message?.bundle) {
            case 'openai_chatgpt':  return <MessageChatGPT uuid={message.uuid} key={i} />
            case 'shablon':         return <MessageShablon message={message} key={i} />
            default:                return <MessageClient message={message} key={i} />
          }
      })}
      {/* <div ref={endRef} /> */}
    </div>
  )
}

export default Chat