import React, { useState, useEffect } from 'react'
import Form from './Form'
import Html from '../atoms/Html'

function ShablonFormFull({ shablon = {}, onSubmit = () => {}, apiErrors = {} }) {
  const [loadedFields, setLoadedFields] = useState([])

  useEffect(() => {
    if (shablon?.drompt?.tokens) {
      const fields = Object.values(shablon.drompt.tokens).reduce((fields, token, i) => {
        fields.push({
          ...token,
          type: token?.widget,
          initial: token?.example,
        })

        return fields
      }, [])

      setLoadedFields(fields)
    }
  }, [shablon])

  return (
    <div className='col items-center justify-center content-center'>
      <Html html={shablon?.field_description} className='leading-normal tracking-wide text-sm mb-10' />
      <section className='w-full max-w-lg'>
        <Form submitButton='Submit Drompt!' onSubmit={onSubmit} errors={apiErrors} fields={loadedFields} />
      </section>
    </div>
  )
}

export default ShablonFormFull