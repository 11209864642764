import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

const ChatForm = ({ submitMessage, buzzy }) => { 
  const init = {
    field_content: '',
  }

  const schema = Yup.object({
    field_content: Yup.string()
      .min(3, 'Prompt must be at least 1 characters')
      .required('Prompt is required')
  })

  const handleKeyDown = (event, form) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault()
      form.submitForm()
    }
  }

  const handleSubmit = (values, form) => {
    form.resetForm()
    return submitMessage && submitMessage(values)
  }

  return (
    <Formik initialValues={init} validationSchema={schema} onSubmit={handleSubmit} enableReinitialize={true}>
      {form => (
        <Form className={`flex flex-row gap-5 my-5 items-strech relative container w-full max-w-4xl`}>
            <div className={`field field-textarea relative w-full`}>
              <Field as="textarea" name='field_content' placeholder='' className='min-h-14 h-14 text-sm text-gray-700 dark:text-gray-200 w-full bg-gray-100 focus:bg-white dark:bg-gray-700 py-3 px-5 tracking-wider rounded-3xl border border-gray-200 dark:border-gray-600 focus:border-gray-400 dark:focus:hover:border-gray-400 hover:border-gray-400 dark:hover:border-gray-400 outline-0' onKeyDown={(event) => handleKeyDown(event, form)} />
            </div>
            <button type='submit' disabled={buzzy || !form.isValid || !form.dirty} className={`absolute right-14 bottom-4 text-slate-200 bg-slate-900 uppercase text-sm  py-2 px-2 rounded-full border-0`}>
              { buzzy ? '...' : 'go!' }
            </button>
        </Form>
      )}
    </Formik>
  )
}

export default ChatForm