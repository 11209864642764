import { useModalWindow } from 'react-modal-global'
import Form from '../Form'

const ModalShablonToken = () => {
  const modal = useModalWindow()
  const { token, saveToken } = modal.params

  const onSubmit = (values) => {
    saveToken(values)
    modal.close()
  }
  
  return (
    <div className='relative z-10 flex-1 bg-white max-w-3xl cursor-default p-16'>
      { (() => {
        switch (token?.type) {
          case 'text':  return <ShablonTokenFormText token={token} onSubmit={onSubmit} />
          case 'number':  return <ShablonTokenFormNumber token={token} onSubmit={onSubmit} />
          case 'select':  return <ShablonTokenFormSelect token={token} onSubmit={onSubmit} />
          default: return <div></div>
        }
      })()}
    </div>
  )
}

const ShablonTokenFormText = ({ token, onSubmit }) => {
  return (
    <Form 
      submitButton='Save' 
      onSubmit={onSubmit} 
      fields={[
        {
          id: 'type',
          type: 'hidden',
          initial: 'text',
        },  
        {
          id: 'id',
          type: 'hidden',
          initial: token?.id,
        },   
        {
          id: 'label',
          type: 'text',
          label: 'Label',
          initial: token?.label || '',
          validation: {
            min: 3,
            max: 52,
            trim: true,
            required: true,
          }
        },
        {
          id: 'example',
          type: 'text',
          label: 'Example',
          initial: token?.example || '',
          validation: {
            min: 3,
            max: 52,
            trim: true,
          }
        },
        {
          id: 'widget',      
          type: 'select',
          label: 'Widget',
          initial: token?.widget || 'text',
          options: {
            text: 'Text',
            textarea: 'Textarea',
          }
        },
        {
          id: 'description',
          type: 'text',
          label: 'Description',
          initial: token?.description || '',
          validation: {
            min: 3,
            max: 512,
            trim: true,
          }
        },
      ]} 
    /> 
  )
}

const ShablonTokenFormNumber = ({ token, onSubmit }) => {
  return (
    <Form 
      submitButton='Save' 
      onSubmit={onSubmit} 
      fields={[
        {
          id: 'type',
          type: 'hidden',
          initial: 'number',
        },  
        {
          id: 'id',
          type: 'hidden',
          initial: token?.id,
        },   
        {
          id: 'label',
          type: 'text',
          label: 'Label',
          initial: token?.label || '',
          validation: {
            min: 3,
            max: 52,
            trim: true,
            required: true,
          }
        },
        {
          id: 'widget',      
          type: 'select',
          label: 'Widget',
          initial: token?.widget || 'text',
          options: {
            number: 'Number',
            // checkboxs: 'checkboxs',
          }
        },
        {
          id: 'description',
          type: 'text',
          label: 'Description',
          initial: token?.description || '',
          validation: {
            min: 3,
            max: 512,
            trim: true,
          }
        },
      ]} 
    /> 
  )
}

const ShablonTokenFormSelect = ({ token, onSubmit }) => {
  return (
    <Form 
      submitButton='Save' 
      onSubmit={onSubmit} 
      fields={[
        {
          id: 'type',
          type: 'hidden',
          initial: 'select',
        },  
        {
          id: 'id',
          type: 'hidden',
          initial: token?.id,
        },   
        {
          id: 'label',
          type: 'text',
          label: 'Label',
          initial: token?.label || '',
          validation: {
            min: 3,
            max: 52,
            trim: true,
            required: true,
          }
        },
        {
          id: 'widget',      
          type: 'select',
          label: 'Widget',
          initial: token?.widget || 'text',
          options: {
            select: 'select',
            options: 'Options',
          }
        },
        {
          id: 'description',
          type: 'text',
          label: 'Description',
          initial: token?.description || '',
          validation: {
            min: 3,
            max: 512,
            trim: true,
          }
        },
      ]} 
    /> 
  )
}

export default ModalShablonToken