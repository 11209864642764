import { useLoader } from '../Utils'
import ShablonTeaser from '../atoms/ShablonTeaser'

const ShablonCollection = () => {
  const { data: shablons } = useLoader('shablons')

  return (
    <div className='col items-center'>
      <h1 className='my-8'>Shablons</h1>
      <div className='container w-full flex flex-wrap hexs'>
          {shablons && (shablons).map((shablon, i) => <ShablonTeaser uuid={shablon.uuid} key={i} />)}
      </div>
    </div>
  )
}

export default ShablonCollection