import { ModalController } from 'react-modal-global'
import ModalShablonToken from './ModalShablonToken'

import 'react-modal-global/styles/modal.scss'
import '../../assets/modal.scss'

const Modal = new ModalController({
  components: {
    addShablonToken: ModalShablonToken,
  }
})

export default Modal
