import parse from 'html-react-parser'
import { uuid } from '../Utils'
import { Field } from 'formik'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TokenMark from '../atoms/TokenMark'
import Modal from './modal/Modal'
import '../assets/form.scss'

const FieldLabel = ({ label }) => <label>{ label && parse(label) }</label>
  
const FieldError = ({ error }) => <div className={`error`}>{ error && parse(error) }</div>

const FieldWrapper = ({ type, value, error, touched, required, className, children}) => {
  return (
    <div className={`field field-${type} field-${error ? 'error' : value ? 'valide' : 'empty'} field-${touched ? 'touched' : 'untouched'} field-${required ? 'required' : 'optional'} ${className}`}>
      { children }
    </div>
  )
}

export const HiddenField = ({ name }) => <Field type='hidden' name={name} />

export const SimpleField = ({ type, name, label, placeholder = '', value, error, touched, required, className = '' }) => {
  return (
    <FieldWrapper 
      type={type}
      value={value}
      error={error}
      required={required} 
      touched={touched}
      className={className}
    >
      { label && <FieldLabel label={label} />}
      <Field type={type} name={name} placeholder={placeholder} />
      <FieldError error={error} />
    </FieldWrapper>
  )
}

export const TextareaField = ({ name, label, value, error, touched, required, className, max }) => {
  return (
    <FieldWrapper 
      type='textarea' 
      value={value}
      error={error}
      required={required} 
      touched={touched}
      className={className}
    >
      { label && <FieldLabel label={label} /> }
      <Field as='textarea' name={name} placeholder={label} />
      {max && <div className='text-xs text-gray-light absolute right-0'> {value.length} / {max} characters</div>}
      <FieldError error={error} />
    </FieldWrapper>
  )
}

export const SelectField = ({ name, label, options = {}, placeholder, value, error, touched, required, className }) => {
  console.log(options);
  
  return (
    <FieldWrapper 
      type='select' 
      value={value}
      error={error}
      required={required} 
      touched={touched}
      className={className}
    >
      { label && <FieldLabel label={label} /> }
      <Field as='select' name={name}>
        { placeholder && <option value='' className='' key={-1}>{ placeholder }</option> }
        { Object.keys(options).map(key => <option value={key} key={key}>{options[key]}</option>) }
      </Field>
      <FieldError error={error} />
    </FieldWrapper>
  )
}

export const OptionsField = ({ name, label, options = {}, value, error, touched, required, className }) => {
  const Option = ({ name, value, label }) => (
    <div className='relative h-6 flex items-stretch'>
      <Field type='radio' name={name} value={value} id={value} className='hidden' />
      <label className='pl-8 text-gray-main flex items-center cursor-pointer' htmlFor={value}>{label}</label>
    </div>
  )

  return (
    <FieldWrapper 
      type='options' 
      value={value}
      error={error}
      required={required} 
      touched={touched}
      className={className}
    >
      { label && <FieldLabel label={label} /> }
      <div role='group' aria-labelledby={name}>
        { Object.keys(options).map(key => <Option name={name} value={key} label={options[key]} key={key} />) }
      </div>
      <FieldError error={error} />
    </FieldWrapper>
  )
}

export const CheckboxField = ({ name, label, value, error, touched, required, className }) => {
  return (
    <FieldWrapper 
      type='checkbox' 
      value={value}
      error={error}
      required={required} 
      touched={touched}
      className={className}
    >
      <label className='!flex gap-3 justify-start cursor-pointer mt-2'>
        <Field type='checkbox' name={name} className='hidden' />
        <div className={`flex-shrink-0 w-9 h-6 p-1 my-1 rounded-2xl col justify-center ${value ? ' bg-main-800' : ' bg-trans'}`}>
          <div className={`w-4 h-4 bg-white rounded-full transition-all ${value ? 'translate-x-3' : ''}`} />
        </div>
        <div className='text-black'>{ label + (required ? ' *' : '') }</div>
      </label>
      <FieldError error={error} />
    </FieldWrapper>
  )
}

export const PasswordField = ({ name, label, placeholder, value, error, touched, required, className }) => {
  return (
    <FieldWrapper 
      type='password' 
      value={value}
      error={error}
      required={required} 
      touched={touched}
      className={className}
    >
      { label && <FieldLabel label={label} /> }
      <Field type='password' name={name} placeholder={placeholder || label} />
      <FieldError error={error} />
    </FieldWrapper>
  )
}

export const PasswordConfirmField = ({ label, values, value, error, errors, touched, className }) => {
  return (
    <FieldWrapper 
      type='password' 
      value={value}
      error={error}
      required={true} 
      touched={touched}
      className={className}
    >
      <div className='pb-5'>
        { label && <FieldLabel label={'Create a new Password'} /> }
        <Field type='password' name='pass' placeholder='Password' />
        <FieldError error={errors?.pass} />
      </div>
      <div>
        { label && <FieldLabel label={'Verify Password'} /> }
        <Field type='password' name='pass2' placeholder='confirm password' />
        <FieldError error={errors?.pass2} />
      </div>
    </FieldWrapper>
  )
}

export const EditorField = ({ name, label, value, error, touched, required, className, setFieldValue }) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content: value,
    onUpdate({ editor }) {
      setFieldValue(name, editor.getHTML())
    },
  })
    
  return (
    <FieldWrapper 
      type='editor' 
      required={required} 
      value={value}
      touched={touched}
      error={error}
      className={className}
    >
      { label && <FieldLabel label={label} /> }
      <div className='editor'>
        <ul className='toolbar'>
          <li onClick={() => editor.chain().focus().toggleBold().run()} disabled={!editor.can().chain().focus().toggleBold().run()}>Bold</li>
        </ul>
        <EditorContent editor={editor} className='prose max-w-none' />
      </div>
      <FieldError error={error} />
    </FieldWrapper>
  )
}

export const ShablonEditorField = ({ name, label, value, error, touched, required, className, setFieldValue }) => {
  const editor = useEditor({
    extensions: [StarterKit, TokenMark],
    content: value.prompt,
    editorProps: {
      handleClick(view, pos) {
        if (!editor.can().chain().focus().updateToken().run()) return

        const node = view.state.doc.nodeAt(pos)
        // console.log(view.state.doc.nodeAt(pos));
        
        if (!node) return

        const tokenMark = node.marks.find(mark => mark.type.name === 'token')
        if (!tokenMark) return
        
        Modal.openNamed('addShablonToken', {
          token: tokenMark.attrs,
          saveToken: (attrs) => {
            editor.chain().updateToken(attrs).insertContent(attrs.example || attrs.label).run()
            return true
          },
        })
      },
    },
    onUpdate({ editor }) { 
      const tokens = {}
      editor.state.doc.descendants((node, pos) => {
        if (node.isText) {
          node.marks.forEach(mark => {
            if (mark.type.name === 'token') {
              tokens[mark.attrs.id] = mark.attrs
            }
          })
        }
      })
      if (setFieldValue) {
        setFieldValue(name, {
          prompt: editor.getHTML(),
          tokens: tokens
        })
      }
    },
  })

  const addToken = (attr) => {
    if (editor.state.selection.empty) return
 
    Modal.openNamed('addShablonToken', {
      token: {
        ...attr, 
        id: uuid(attr.type)
      },
      saveToken: (token) => {
        editor.chain().focus().setToken(token).insertContent(token.example || token.label).run()
        return true
      },
    })
  }

  return (
    <FieldWrapper 
      type='editor' 
      required={required} 
      value={value}
      touched={touched}
      error={error}
      className={className}
    >
      { label && <FieldLabel label={label} /> }
      <div className='editor'>
        <ul className='toolbar tokens'>
          <li onClick={() => addToken({ type: 'text', widget: 'text'})} className='token'>Text</li>
          <li onClick={() => addToken({ type: 'number', widget: 'number'})} className='token'>Number</li>
          <li onClick={() => addToken({ type: 'select', widget: 'select'})} className='token'>Select</li>
        </ul>
        <EditorContent editor={editor} className='prose max-w-none' />
      </div>
      <FieldError error={error} />
    </FieldWrapper>
  )
}

// export const PrivacyCheckboxField = ({ name, values, errors, touched, className }) => {
//   return (
//     <div className={`field field-checkbox field-${errors?.[name] ? 'error' : values[name] ? 'valide' : 'empty'} field-${touched?.[name] ? 'touched' : 'untouched'} ${className}`}>
//       <label className='!flex gap-3 justify-start cursor-pointer mt-2'>
//         <Field type='checkbox' name={name} className='hidden' />
//         <div className={`flex-shrink-0 w-9 h-6 p-1 my-1 rounded-2xl   transition-all ${values[name] ? ' bg-main-800' : ' bg-gray-400'}`}>
//           <div className={`w-4 h-4 bg-white rounded-full transition-all ${values[name] ? 'translate-x-3' : ''}`} />
//         </div>
//         {/* <div className='text-black'>Ich habe die Hinweise zum <Link to='/datenschutz' className='underline'>Datenschutz</Link> gelesen und akzeptiere diese.*</div>  */}
//         <div className='text-black'>Ich habe die <a href='/datenschutz' target='_blank' className='underline'>Datenschutzhinweise</a> gelesen und bin mit dem darin beschriebenen Umgang mit meinen personenbezogenen Daten einverstanden. Mir ist bekannt, dass ich für die von mir freiwillig zur Verfügung gestellten Daten ein Widerspruchsrecht für die Zukunft habe. *</div> 
//       </label>      
//       <FieldError error={errors?.[name]} show={errors?.[name] && touched?.[name]} />
//     </div>
//   )
// }