import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useLoader } from '../Utils'
import Markdown from 'react-markdown'

function MessageChatGPT({ uuid }) {
  const queryClient = useQueryClient()
  const { data: message, isLoading } = useLoader('message', { uuid: uuid })

  useEffect(() => {
    if (message?.status === 'pending') {
      const eventSource = new EventSource(`/api/chat/${message.chat_id}/${message.uuid}/stream`)
      eventSource.onmessage = (event) => {
        if (!(/^\{"id":\d+.*}$/.test(event.data))) {
          eventSource.close();
        } else {
          const new_message = JSON.parse(event.data);
          queryClient.setQueryData([`message`, message.uuid], () => new_message);
        }

        if (message.status === 'completed') {
          eventSource.close();
        }
      }
    } 
  }, [message, queryClient])

  return (
    <div className='h-auto '>
      {/* <div className='  col gap-1 w-full'> */}
        {isLoading ? 
          <div>Loading </div> :
          <div className=''>
            <div className='prose max-w-none   dark:text-gray-300'>
              { (isLoading || !message?.status || message?.status === 'pending') ? 
                <div>Loading</div> :
                <Markdown className=' '>
                  { message?.field_content }
                </Markdown>
              }    
              </div>
          </div>
        }
      {/* </div> */}
    </div>
  )
}

export default MessageChatGPT