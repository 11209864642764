import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { useLoader, postToApi } from '../Utils'
import ShablonTeaser from '../atoms/ShablonTeaser'
import ChatForm from '../components/ChatForm'
import '../assets/hex.scss'

const Frontpage = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  // const { data: user } = useLoader('user')
  const { data: shablons } = useLoader('shablons')
  const [ mode ] = useState('stream')
  
  const { mutate: sendChat } = useMutation(async (chat) => {
    const { data: newChat } = await postToApi(`chat`, chat)
    return newChat
  }, {
    onMutate: async () => { 
      await queryClient.cancelQueries([`chats`])
    },
    onSuccess: (respone) => {
      queryClient.setQueryData([`chat`, respone.chat.uuid], (old = {}) => (respone.chat)) 
      queryClient.setQueryData([`chats`], (old = []) => [respone.chat, ...old]) 

      queryClient.setQueryData([`chat`, respone.chat.uuid, 'messages'], (messages = []) => {
        
        messages.push(respone.messages.client)
        messages.push(respone.messages.assistent)

        return messages;
      }) 

      navigate(`/chat/${respone.chat.uuid}`, { replace: true })
    },
  })

  const submitMessage =  async (message) => {
    sendChat({
      // chat: uuid,
      assistent: 'chatgpt',
      mode: mode,
      message: {
        bundle: 'text',
        ...message,
      }
    }, {})

    return true
  }

  return (
    <div className=' w-full max-w-full py-20 flex flex-col gap-5 items-center'>
      <h1 className='text-center text-4xl/7  font-bold'>Welcome to Alpha ✨</h1>
      <h1 className='text-center text-2xl/4 '>{getRandomGreeting()}</h1>
      <div className='bg-slate-400s  w-full max-w-3xl'>
        <ChatForm submitMessage={submitMessage}  /> 
        
      </div>
      <div className='w-full col items-center gap-10 pt-10 pb-32'>
        <div className='container w-full flex flex-wrap hexs'>
          {shablons && (shablons).map((shablon, i) => <ShablonTeaser uuid={shablon.uuid} key={i} />)}
        </div>
      </div>
    </div>
  )
}

function getRandomGreeting() {
  const greetings = [
      "What can I help you with today?",
      "How may I support you today?",
      "What do you need assistance with today?",
      "How can I make your day easier?",
      "Is there something specific I can help you with?",
      "What can I do for you today?",
      "How can I be of service today?",
      "What brings you here today?",
      "What can I help you solve today?",
      "How can I help make things clearer?",
      "What’s on your mind today?",
      "How can I lend a hand today?",
      "What would you like to tackle today?",
      "How can I guide you today?",
      "What challenge can I help you with today?",
      "How can I assist in achieving your goals today?",
      "Is there anything I can clarify for you today?",
      "What’s the top priority I can help you with today?",
      "How can I contribute to your success today?",
      "What can I walk you through today?"
  ];
  const randomIndex = Math.floor(Math.random() * greetings.length);
  return greetings[randomIndex];
}

export default Frontpage