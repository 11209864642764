import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useLoader, postToApi, patchToApi } from '../Utils'
import Form from './Form'

const ShablonEditor = ({ shablon }) => {
  const navigate = useNavigate()
  const client = useQueryClient()
  const { data: terms } = useLoader('terms', { vid: 'shablon_categories' })
  const [options, setOptions] = useState([])
 
  useEffect(() => {
    if (terms) {
      let options = {}
      terms.map((term) => options[term.tid] = term.name)
      setOptions(options)
    }
  }, [terms])
  
  const postShablon = useMutation(async (values) => {
    const { data: shablon } = await postToApi(`shablon`, values)
    return shablon
  }, {
    onSuccess: async (shablon) => { 
      client.setQueryData([`shablon`, shablon.uuid], old => shablon) 
      client.setQueryData([`shablons`], old => [...old, shablon]) 
      navigate(`/shablon/${shablon.uuid}`, { replace: true })
    },
  })

  const patchShablon = useMutation(async ({ shablon, values }) => {
    const { data: newShablon } = await patchToApi(`shablon/${shablon.uuid}`, values)
    return newShablon
  }, {
    onMutate: async () => { await client.cancelQueries([`shablon`, shablon.uuid]) },
    onSuccess: async (shablon) => { client.setQueryData([`shablon`, shablon.uuid], old => shablon) },
  })

  const updateShablon = async (values) => {
    if (shablon) {
      await patchShablon.mutate({ shablon: shablon, values: values })
    } else {
      await postShablon.mutate(values)
    }

    return true
  }

  const loadedFields = [
    {
      id: 'bundle',
      type: 'hidden',
      initial: 'simple',
    },    
    {
      id: 'label',
      type: 'text',
      label: 'Label',
      initial: shablon?.label || '',
      validation: {
        min: 3,
        max: 52,
        trim: true,
        required: true,
      }
    },
    {
      id: 'field_category',      
      type: 'select',
      label: 'Category',
      initial: shablon?.field_category?.tid || '',
      options: options
    },
    {
      id: 'field_privacy',
      type: 'select',
      label: 'Privacy',
      initial: shablon?.field_privacy || 'private',
      options: {
        private: 'Private', 
        company: 'For everyone in the Company', 
        public: 'For everyone', 
      }
    },
    {
      id: 'field_description',
      type: 'editor',
      label: 'Description',
      initial: shablon?.field_description || '',
      // validation: {
      //   trim: true,
      //   required: true,
      //   min: 30,
      // }
    },
    {
      id: 'drompt',
      type: 'editor-shablon',
      label: 'Prompt',
      initial: shablon?.drompt || {prompt: '', tokens: {}},
    },
  ]

  return (
    <Form submitButton='Save' onSubmit={updateShablon} fields={loadedFields} /> 
  )
}

export default ShablonEditor