import { useQuery, useQueryClient, useMutation } from 'react-query'
import Cookies from 'js-cookie'
import parse from 'html-react-parser'
import axios from 'axios'

axios.defaults.headers.common['X-CSRF-Token'] = Cookies.get('token') // Cookies.remove('token') 
	|| await axios.get(`/session/token`).then(res => Cookies.set('token', res.data, {expires: 60}))

export const loadFromApi = async (uri, params = {}) => axios.get(`/api/${uri}`, { params: params }).then(({data}) => data)  
export const postToApi   = async (uri, data = {}) => axios.post(`/api/${uri}`, data)
export const patchToApi  = async (uri, data = {}) => axios.patch(`/api/${uri}`, data)

const entityTypes = {
	user: () => ({
		url: () => `user/data`,
		key: [`user`],
		maxLifetime: 60 * 60 * 60 * 60 * 60,
		retry: 0,
		load: () => loadFromApi(`user/data`)
	}),
	menu: ({name}) => ({
		url: () => `menu_items/${name}`,
		key: [`menu`, name],
		maxLifetime: 60 * 60 * 60 * 60 * 60,
		retry: 0,
		load: () => loadFromApi(`menu_items/${name}`)
	}),
	content: ({alias}) => ({
		key: [`content`, alias],
		isReady: alias ? true : false,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => alias && loadFromApi(`content`, {alias: alias})
	}),
	chats: () => ({
		key: [`chats`],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => loadFromApi(`chat/all`)
	}),
	chat: ({uuid}) => ({
		key: [`chat`, uuid],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => uuid && loadFromApi(`chat/${uuid}`)
	}),
	message: ({uuid}) => ({
		key: [`message`, uuid],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => uuid && loadFromApi(`message/${uuid}`)
	}),
	messages: ({uuid}) => ({
		key: [`chat`, uuid, 'messages'],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => uuid && loadFromApi(`chat/${uuid}/messages`)
	}),
	shablons: () => ({
		key: [`shablons`],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => loadFromApi(`shablon/all`)
	}),
	shablon: ({uuid}) => ({
		key: [`shablon`, uuid],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => uuid && loadFromApi(`shablon/${uuid}`)
	}),
	shablonChats: ({id}) => ({
		key: [`shablon`, id, `chats`],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => loadFromApi(`shablon/${id}/chats`)
	}),
	terms: ({vid}) => ({ // shablon_categories
		key: [`vocabulary`, vid],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => loadFromApi(`vocabulary/${vid}`) 
	}),
}

export const useLoader = (entityTypeId, props = {}) => {
	const { key, load, maxLifetime, initialData, retry } = entityTypes[entityTypeId](props)

	return useQuery({
		queryKey: key,
		initialData: initialData,
		staleTime: maxLifetime,
		retry: retry,
		queryFn: () => load(),
		onError: (error) => error,
	})
}

export const useUserLogin = () => {
	const queryClient = useQueryClient()

	const loginUser = useMutation(async (values) => {
		const res = await postToApi(`user/login`, values)
		if (res.status === 200) {
			return await res.data
		} else {
			throw new Error(res.statusText, { cause: res }) 
		}
	}, {
		onMutate: async () => await queryClient.cancelQueries('user'),
		onSuccess: async (user) => queryClient.setQueryData('user', user),
	})

	return loginUser
}

export const parseHTML = (html, empty = null) => {
	const options = {
		trim: true,
	}

	return html ? parse(html, options) : empty
}

export const formatDate = (timestamp, format, lang = 'en-US') => {
	if (!timestamp) return ''
 
	const options = (() => {
		switch (format) {
			case 'full': return  {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}
			case 'short': return {month: '2-digit', day: '2-digit', hour: 'numeric', minute: '2-digit'}
			default: return      {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}
		}
	})()

	const date = new Date(timestamp * 1000)
	const formater = new Intl.DateTimeFormat(lang, options)

	return formater.format(date)
}

export function uuid(prefix = false) {
	return (prefix || null) + ( Math.random() * 1000).toFixed()
}