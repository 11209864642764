import { Helmet } from 'react-helmet-async'

const MetaData = ({ children, title, metatags = [] }) => {
  return (
    <Helmet>
      <title>{ title }</title>
      { metatags.map((metatag, i) => <Metatag metatag={metatag} key={i} />)}
      { children }
    </Helmet>
  )
}
 
const Metatag = ({metatag = []}) => {
  switch (metatag.tag) {
    case 'meta': return <meta name={metatag?.attributes?.name} content={metatag?.attributes?.content} />
    case 'link': return <link href={metatag?.attributes?.href} rel={metatag?.attributes?.rel}/>
    default: return null
  }
}

export default MetaData