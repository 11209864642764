import React from 'react'
import { useInView } from 'react-intersection-observer'
import LoadingSpinner from './LoadingSpinner'

const LazyTeaser = ({ children, className }) => {
  const [ref, inView] = useInView({ 
    triggerOnce: true , 
    // rootMargin: " 300px 0px -50px 0px" 
  });
  return (
    <div className={`transition-all  ${className}  ${inView ? '!min-h-auto bg-transparent' : 'bg-neutral-50'}`} ref={ref} >
      { inView ? (children) : <div className='flex justify-center items-center animate-fade-in-fast h-full p-3'><LoadingSpinner className='h-full' /></div>}
    </div>
  )
}

export default LazyTeaser