import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useLoader } from '../Utils'
// import Footer from '../components/Footer'
import Paragraph from '../components/Paragraph'
import MetaData from '../components/MetaData'
import LoadingSpinner from '../atoms/LoadingSpinner'

const Content = () => {
  const { pathname } = useLocation()
  const { data: entity, isLoading, error } = useLoader('content', { alias: pathname.replace(/\/$/, '') || 'home' })
  
  return (
    <>
      {isLoading ? <LoadingScreen /> :
        (() => {
          switch (entity?.type) {
            case 'node': return <Node node={entity} />
            default: return <Error error={error} />
          }
        })()
      }
    </>
  )
}

const LoadingScreen = () => {
  return (
    <div className='col items-center justify-center animate-fade-in'>
      <LoadingSpinner className='' />
    </div>
  )
}

const Node = ({ node }) => {
  const { title, metatag, edit_link, field_paragraphs: paragraphs } = node
  const { pathname } = useLocation()
  useEffect(() => { window.scrollTo(0, 0) }, [pathname])

  return (
    <div className='col items-center gap-20 flex-grow-0 w-full animate-fade-in-fast'>
      <MetaData title={title} metatags={metatag} />
      <div className='col w-full'>
        { paragraphs && (paragraphs).map((paragraph, i) => <Paragraph paragraph={paragraph} key={i} />) }
      </div>
      { edit_link && <div className='container relative box justify-end'><a href={edit_link} className='fixed top-20 translate-x-[150%] z-50 bg-white text-black px-5 py-1 rounded-md transition-all opacity-90 hover:opacity-100'>Edit</a></div> }
    </div>
  )
}

const Error = ({error}) => {
  return (
    <main className='w-full bg-white flex-col justify-start items-center gap-2.5 inline-flex'>
        {(() => {
          switch (error?.response?.status) {
            case 404: return <Error404 />
            case 403: return <Error403 />
            default: return <Error500 />
          }
        })()}
    </main>
  )
}

const Error404 = () => {
  return (
    <div>NotFoundHttpException</div>
  )
}

const Error403 = () => {
  return (
    <div>AccessDeniedHttpException</div>
  )
}

const Error500 = () => {
  return (
    <div>Error500</div>
  )
}

export default Content