import { useParams } from 'react-router-dom'
import { useLoader } from '../Utils'
import ShablonFormFull from '../components/ShablonFormFull'
import ShablonEditor from '../components/ShablonEditor'

const ShablonForm = () => {
  const { uuid } = useParams()
  const { data: shablon, isLoading } = useLoader('shablon', { uuid: uuid })

  const submitDrompt = () => {
    return true
  }

  return (
    <div className='flex items-center absolute inset-0 pt-16'>
      <div className='container  col items-center pt-10 h-full overflow-y-auto'>
        <div className='max-w-4xl  w-full'>
          { isLoading ? '' : <ShablonEditor shablon={shablon} /> }
        </div>
      </div>
      <div className='container max-w-xl col items-center pt-10 h-full  overflow-y-auto'>
        <div className='max-w-xl w-full'>
          <h1 className='text-2xl'>{shablon?.label || 'Create new shablon'}</h1>
          { isLoading ? '' : <ShablonFormFull shablon={shablon} onSubmit={submitDrompt} className='pt-10' /> }
        </div>
      </div>
    </div>
  )
}

export default ShablonForm